<template>
    <div class="department-index">
        <navigationBar
            :routerList="this.$router.history.current.meta.breadcrumb"
            @buttonClickEmit0="addOrEditDept"
            :buttonList="buttonList"
        />
        <!-- <div>
            <el-button type="primary" size="small" @click="addOrEditDept()">新增</el-button>
        </div>-->
        <div class="department-tree">
            <el-tree :data="deptList" node-key="id" style="margin-top:10px;" class="filter-tree">
                <span class="custom-tree-node" slot-scope="{ node, data }">
                    <span>{{ node.label }}</span>
                    <span class="tree-button">
                        <!-- <span >{{data.status}}</span> -->
                        <span v-if="data.status=='启用'" style="color: #67C23A">{{data.status}}</span>
                        <span v-if="data.status=='停用'" style="color:#FF0000">{{data.status}}</span>
                        <el-button
                            type="danger"
                            icon="el-icon-delete"
                            circle
                            size="mini"
                            v-on:click.stop="deleteDeptId(data)"
                        ></el-button>
                        <el-button
                            type="primary"
                            icon="el-icon-edit"
                            circle
                            size="mini"
                            v-on:click.stop="addOrEditDept(node,data)"
                        ></el-button>
                    </span>
                </span>
            </el-tree>
        </div>

        <el-dialog
            :title="titleDepartment"
            :visible.sync="dialogFormVisible"
            width="40%"
            @close="handleClose"
        >
            <el-form
                ref="addForm"
                :model="addForm"
                :rules="rules"
                v-loading="formLoading"
                label-width="140px"
            >
                <el-form-item label="上级部门" prop="defaultData">
                    <el-cascader
                        placeholder="请选择上级部门"
                        :options="this.deptList"
                        filterable
                        change-on-select
                        clearable
                        @change="changePDepartment"
                        v-model="defaultData"
                        style="width:80%"
                    ></el-cascader>
                </el-form-item>

                <el-form-item label="部门名称" prop="dept_name">
                    <el-input v-model="addForm.dept_name" style="width: 80%;"></el-input>
                </el-form-item>
                <el-form-item label="层级" prop="level">
                    <el-input v-model.number="addForm.level" style="width: 80%;"></el-input>
                </el-form-item>
                <el-form-item prop="district" label="所属区域：">
                    <el-select v-model="addForm.district" placeholder="所属区域：" style="width: 80%;">
                        <el-option
                            v-for="item in districtInfo"
                            :key="item.dictKey"
                            :label="item.dictValue"
                            :value="item.dictKey"
                        ></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="可用状态" prop="status">
                    <el-radio-group v-model="addForm.status">
                        <el-radio :label="1">可用</el-radio>
                        <el-radio :label="2">不可用</el-radio>
                    </el-radio-group>
                </el-form-item>
            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button size="small" type="primary" @click="submitForm">提 交</el-button>
                <el-button size="small" @click="dialogFormVisible = false">取 消</el-button>
            </div>
        </el-dialog>
    </div>
</template>

<script>
import navigationBar from "@/components/navigationBar";
import setting from "@/utils/config/index.js";
import {
    deleteDepartmentId,
    getDeptInfoList,
    updateOrAddDepartment,
} from "@/api/getUcenterData";
export default {
    data() {
        return {
            buttonList: [
                {
                    backgroundColor: "#52D1AF",
                    icon: "/static/img/add.png",
                    text: "新增",
                },
            ],
            addForm: {
                id: "",
                dept_name: "",
                status: "",
                level: "",
                district: "",
            },
            titleDepartment: "",
            listLoading: false,
            departmentList: [],
            dialogFormVisible: false,
            formLoading: false,
            department: [],
            deptList: [],
            pDepartmentCode: "",
            defaultData: [],
            disabled: false,
            nodeData: [],
            rules: {
                status: [{ required: true, message: "可用状态不能为空" }],
                dept_name: [{ required: true, message: "部门名称不能为空" }],
                level: [{ type: "number", message: "层级必须为数字值" }],
            },
            districtInfo: [],
        };
    },
    components: {
        navigationBar,
    },
    created() {
        this.getDeptInfoList();
    },
    mounted() {},
    methods: {
        handleClose() {
            this.nodeData.disabled = false;
        },
        //选择部门
        changePDepartment(value) {
            this.pDepartmentCode = value[value.length - 1];
        },
        //部门信息弹出窗口
        addOrEditDept(node, data) {
            if (data != null) {
                if (node.level == 3) {
                    this.defaultData = [
                        node.parent.parent.data.value,
                        node.parent.data.value,
                    ];
                } else if (node.level == 2) {
                    this.defaultData = [node.parent.data.value];
                } else {
                    this.defaultData = [];
                }
                let stautsNum;
                if (data.status == "启用") {
                    stautsNum = 1;
                } else {
                    stautsNum = 2;
                }
                this.addForm = {
                    id: data.value,
                    dept_name: data.label,
                    status: stautsNum,
                    level: data.level,
                    district: data.district,
                };
                this.titleDepartment = "编辑部门信息";
                data.disabled = true;
                this.nodeData = data;
                this.pDepartmentCode = node.parent.data.value;
            } else {
                this.titleDepartment = "新增部门信息";
                this.addForm = {
                    status: 1,
                };
                this.defaultData = [];
            }
            this.dialogFormVisible = true;
        },
        //部门列表信息
        getDeptInfoList: function () {
            getDeptInfoList().then((resData) => {
                let farr = [];
                let sarr = [];
                let tarr = [];

                if (resData.resultCode == 1) {
                    let res = resData.data;

                    if (res.length > 0) {
                        let resData = res;
                        for (var i = 0; i < resData.length; i++) {
                            if (!farr[resData[i].fId]) {
                                let statusName = "";
                                if (resData[i].fStatus == 1) {
                                    statusName = "启用";
                                } else {
                                    statusName = "停用";
                                }
                                farr[resData[i].fId] = {
                                    label: resData[i].fDeptName,
                                    value: resData[i].fId,
                                    status: statusName,
                                    disabled: false,
                                    level: resData[i].fLevel,
                                    district: resData[i].fDistrictName,
                                };
                            }
                            if (!sarr[resData[i].sId]) {
                                let statusName = "";
                                if (resData[i].sStatus == 1) {
                                    statusName = "启用";
                                } else {
                                    statusName = "停用";
                                }
                                sarr[resData[i].sId] = {
                                    label: resData[i].sDeptName,
                                    value: resData[i].sId,
                                    parent_id: resData[i].sParentId,
                                    status: statusName,
                                    disabled: false,
                                    level: resData[i].sLevel,
                                    district: resData[i].sDistrictName,
                                };
                            }
                            if (!tarr[resData[i].tId]) {
                                let statusName = "";
                                if (resData[i].tStatus == 1) {
                                    statusName = "启用";
                                } else {
                                    statusName = "停用";
                                }
                                tarr[resData[i].tId] = {
                                    label: resData[i].tDeptName,
                                    value: resData[i].tId,
                                    parent_id: resData[i].tParentId,
                                    status: statusName,
                                    disabled: false,
                                    level: resData[i].tLevel,
                                    district: resData[i].tDistrictName,
                                };
                            }
                        }
                        for (var i = 0; i < tarr.length; i++) {
                            if (tarr[i]) {
                                if (!sarr[tarr[i].parent_id].children) {
                                    sarr[tarr[i].parent_id].children = [];
                                }
                                sarr[tarr[i].parent_id].children.push(tarr[i]);
                            }
                        }
                        for (var i = 0; i < sarr.length; i++) {
                            if (sarr[i]) {
                                if (!farr[sarr[i].parent_id].children) {
                                    farr[sarr[i].parent_id].children = [];
                                }
                                farr[sarr[i].parent_id].children.push(sarr[i]);
                            }
                        }
                        this.deptList = [];
                        for (var i = 0; i < farr.length; i++) {
                            if (farr[i]) {
                                this.deptList.push(farr[i]);
                                this.disabled = true;
                            }
                        }
                    }
                } else {
                    this.$message({
                        message: resData.resultMsg,
                        type: "error",
                    });
                }
            });
        },
        //保存部门信息
        submitForm: function () {
            this.$refs.addForm.validate((valid) => {
                if (valid) {
                    this.$confirm("确认提交吗？", "提示", {}).then(() => {
                        this.formLoading = true;
                        let para = {
                            id: this.addForm.id,
                            dept_name: this.addForm.dept_name,
                            parent_id: this.pDepartmentCode,
                            status: this.addForm.status,
                            level: this.addForm.level,
                            district: this.addForm.district,
                        };
                        if (
                            this.pDepartmentCode == undefined ||
                            this.pDepartmentCode == ""
                        ) {
                            para = {
                                id: this.addForm.id,
                                dept_name: this.addForm.dept_name,
                                parent_id: 0,
                                status: this.addForm.status,
                                level: this.addForm.level,
                                district: this.addForm.district,
                            };
                        }
                        updateOrAddDepartment(para).then((res) => {
                            this.formLoading = false;
                            if (res.resultCode == 1) {
                                this.$message({
                                    message: "操作成功",
                                    type: "success",
                                    onClose: (this.dialogFormVisible = false),
                                });
                            } else {
                                this.$message({
                                    message: res.resultMsg,
                                    type: "error",
                                });
                            }
                            this.getDeptInfoList();
                        });
                    });
                }
            });
        },
        //删除部门信息
        deleteDeptId: function (data) {
            if (data.children) {
                this.$message({ message: "请先删除子部门", type: "warning" });
            } else {
                this.$confirm("确认删除该记录吗?", "提示", { type: "warning" })
                    .then(() => {
                        this.listLoading = true;
                        let para = { id: data.value };
                        deleteDepartmentId(para).then((res) => {
                            this.listLoading = false;
                            if (res.resultCode == 1) {
                                this.$message({
                                    message: "删除成功",
                                    type: "success",
                                });
                            } else {
                                this.$message({
                                    message: "删除失败",
                                    type: "error",
                                });
                            }
                            this.getDeptInfoList();
                        });
                    })
                    .catch(() => {
                        this.listLoading = false;
                    });
            }
        },
    },
};
</script>
<style lang="scss">
.department-index {
    .department-tree {
        margin-top: 10px;
        .el-tree-node__content {
            height: 40px;
        }
        .el-tree-node {
            border-bottom: 1px solid #eceef6;
        }
        .el-tree-node:last-child {
            border-bottom: 0px solid #eceef6;
        }
        .custom-tree-node {
            flex: 1;
            display: flex;
            align-items: center;
            justify-content: space-between;
            font-size: 16px;
            padding-right: 8px;
            .tree-button {
                // float: right;
                display: inline-block;
                span:first-child {
                    margin-right: 100px;
                }
            }
        }
    }
    .el-dialog__header {
        background: #e9ecee;
        padding: 1rem;
    }
    .el-dialog__headerbtn {
        top: 1.25rem;
    }
    .el-dialog__title {
        font-weight: bold;
    }
}
</style>
